<template>
	<div class="detail-page">
		<div class="detail-page__left">
			<CardLeftBlock
					:backgroundImage="'rank'"
			>
				<template slot="body">
					<div class="lblock-card-img">
						<img src="/img/table-group/big-etsy.png" alt="img">
					</div>
				</template>
			</CardLeftBlock>
		</div>

		<div class="detail-page__right">
			<EtsyRankCardTable/>
		</div>
	</div>
</template>

<script>
	import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
	import EtsyRankCardTable from "./EtsyRankCardTable/EtsyRankCardTable";

	export default {
		name: "EtsyRankCardUser",
		components: {
			CardLeftBlock,
			EtsyRankCardTable,
		}
	}
</script>

<style scoped>
	@media (max-width: 1220px) {
		.lblock-card-img img {
			max-width: 110px;
		}
	}
	@media (max-width: 550px) {
		.lblock-card-img img{
			max-width: 55px;
		}
	}

	@media (min-width: 550px) {
		.detail-page__right {
			margin-top: -30px;
		}
	}
</style>
