<template>
	<EtsyRankCardUser/>
</template>

<script>
	import EtsyRankCardUser from "./EtsyRankCardUser/EtsyRankCardUser";

	export default {
		name: "EtsyRankCard",
		components: {
			EtsyRankCardUser,
		}
	}
</script>

<style scoped>

</style>
