<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--separator">
        <!--<div class="content-tabs__item" v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
          <!--All Listings-->
        <!--</div>-->
        <!--<div class="content-tabs__item" v-bind:class="{active: tab === 2}" @click="changeTab(2)">-->
          <!--Active (9)-->
        <!--</div>-->
        <!--<div class="content-tabs__item " v-bind:class="{active: tab === 3}" @click="changeTab(3)">-->
          <!--Unactive (3)-->
        <!--</div>-->
        <!--<div class="content-tabs__item " v-bind:class="{active: tab === 4}" @click="changeTab(4)">-->
          <!--Drafts (4)-->
        <!--</div>-->
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  export default {
    name: "EtsyRankHead",
    components: {
      ToggleFilterButton,
    },

    props: [
      'showFilter',
      'adminTabs',
      'countFilterParams',
      'activeTab'
    ],

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    created() {

    },

    data() {
      return {
        isShowSearchEngineBlock: false,
        tab: this.activeTab,
      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },
    }
  }
</script>

<style scoped>
  @media (min-width: 680px) {
    .btn-left-block{
      flex-wrap: wrap;
      max-width: 50%;
    }

    .btn-left-block-i{
      margin-left: 0!important;
      margin-right: 15px;
    }
  }

  @media (max-width: 680px){
    .btn-right-block{
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .btn-right-block .btn-right-block-i{
      /*margin-top: 15px;*/
      margin-right: 0!important;
    }

    .btn-right-block .btn-right-block-i:last-child{
      margin-top: 0;
    }

    .btn-left-block{
      display: flex;
      flex-direction: column;
      max-width: 50%
    }

    .btn-left-block-i{
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .btn-left-block-i:last-child{
      margin-top: 0;
      margin-bottom: 0;
    }

    .btn-left-block-i--link{
      font-size: 12px;
    }

    .light-bg-block{
      align-items: flex-end;
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
  }
</style>

