<template>
  <div class="table-filter-wrap">
    <div class="site-table-wrap small-table">
      <table class="site-table" v-if="!isMobileFunc()">
        <thead>
        <tr>
          <th class="text-lg-center">№</th>
          <th>Store Name</th>
          <th>Number of sales</th>
          <th>Country</th>
          <th>Categories</th>
          <th width="100%"></th>
        </tr>
        </thead>
        <tbody>

        <tr>
          <td><div class="rank rank-1"></div></td>
          <td>Ukrainian Treasures</td>
          <td align="right">
            242352
          </td>
          <td>
            Ukraine
          </td>
          <td>
            Craft tools and supplies
          </td>
          <td>

          </td>
        </tr>
        <tr>
          <td><div class="rank rank-2"></div></td>
          <td>Ukrainian Treasures</td>
          <td align="right">
            242352
          </td>
          <td>
            Ukraine
          </td>
          <td>
            Craft tools and supplies
          </td>
          <td>

          </td>
        </tr>
        <tr>
          <td><div class="rank rank-3"></div></td>
          <td>Ukrainian Treasures</td>
          <td align="right">
            242352
          </td>
          <td>
            Ukraine
          </td>
          <td>
            Craft tools and supplies
          </td>
          <td>

          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>Ukrainian Treasures</td>
          <td align="right">
            242352
          </td>
          <td>
            Ukraine
          </td>
          <td>
            Craft tools and supplies
          </td>
          <td>

          </td>
        </tr>

        </tbody>
      </table>

      <div class="table-card" v-else>
        <div class="table-card__list">
          <div class="table-card__item">
            <div class="table-card__item-content"
                 :class="{show: show1}"
            >
              <div class="table-card__item-head">
                <div class="table-card__item-head-row align-items-center">
                  <div class="rank rank-1"></div>
                  <div class="table-card__item-info ml-3">
                    Ukrainian Treasures
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div class="table-card__item-body"
                     v-if="show1"
                >
                  <div class="table-card__item-row custom-row">
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Number of sales
                      </div>
                      <div class="table-card__item-info">
                        242352
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Country
                      </div>
                      <div class="table-card__item-info">
                        Ukraine
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col">
                      <div class="table-card__item-label">
                        Categories
                      </div>
                      <div class="table-card__item-info">
                        Craft tools and supplies
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div class="table-card__item-footer">
                <div class="table-card__item-show"
                     @click="show1 = !show1"
                >
                  Show More
                </div>
              </div>
            </div>
          </div>
          <div class="table-card__item">
            <div class="table-card__item-content"
                 :class="{show: show2}"
            >
              <div class="table-card__item-head">
                <div class="table-card__item-head-row align-items-center">
                  <div class="rank rank-2"></div>
                  <div class="table-card__item-info ml-3">
                    Ukrainian Treasures
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div class="table-card__item-body"
                     v-if="show2"
                >
                  <div class="table-card__item-row custom-row">
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Number of sales
                      </div>
                      <div class="table-card__item-info">
                        242352
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Country
                      </div>
                      <div class="table-card__item-info">
                        Ukraine
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col">
                      <div class="table-card__item-label">
                        Categories
                      </div>
                      <div class="table-card__item-info">
                        Craft tools and supplies
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div class="table-card__item-footer">
                <div class="table-card__item-show"
                     @click="show2 = !show2"
                >
                  Show More
                </div>
              </div>
            </div>
          </div>
          <div class="table-card__item">
            <div class="table-card__item-content"
                 :class="{show: show3}"
            >
              <div class="table-card__item-head">
                <div class="table-card__item-head-row align-items-center">
                  <div class="rank rank-3"></div>
                  <div class="table-card__item-info ml-3">
                    Ukrainian Treasures
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div class="table-card__item-body"
                     v-if="show3"
                >
                  <div class="table-card__item-row custom-row">
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Number of sales
                      </div>
                      <div class="table-card__item-info">
                        242352
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Country
                      </div>
                      <div class="table-card__item-info">
                        Ukraine
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col">
                      <div class="table-card__item-label">
                        Categories
                      </div>
                      <div class="table-card__item-info">
                        Craft tools and supplies
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div class="table-card__item-footer">
                <div class="table-card__item-show"
                     @click="show3 = !show3"
                >
                  Show More
                </div>
              </div>
            </div>
          </div>
          <div class="table-card__item">
            <div class="table-card__item-content"
                 :class="{show: show4}"
            >
              <div class="table-card__item-head">
                <div class="table-card__item-head-row align-items-center">
                  <div class="table-card__item-info pt-2 pb-2">
                    4
                  </div>
                  <div class="table-card__item-info ml-3">
                    Ukrainian Treasures
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div class="table-card__item-body"
                     v-if="show4"
                >
                  <div class="table-card__item-row custom-row">
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Number of sales
                      </div>
                      <div class="table-card__item-info">
                        242352
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Country
                      </div>
                      <div class="table-card__item-info">
                        Ukraine
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col">
                      <div class="table-card__item-label">
                        Categories
                      </div>
                      <div class="table-card__item-info">
                        Craft tools and supplies
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div class="table-card__item-footer">
                <div class="table-card__item-show"
                     @click="show4 = !show4"
                >
                  Show More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore :count="'25'"/>
        </div>
      </div>
    </div>


    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


  </div>
</template>

<script>
  import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../../mixins/mobileFunctions'


  export default {
    name: "EtsyRankTable",

    components: {
      ShowMore,
    },

    mixins: [mixinDetictingMobile],

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,
        show3: false,
        show4: false,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
        isModalInfoIDPopup: '',

      }
    },

    methods: {
      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      cantDeleteProduct(){
        let text = {
          title: 'Ooops',
          txt: 'Sorry, you cannot uninstall this product',
          no: 'Ok'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      removeProduct(){
        let text = {
          title: 'Delete Product?',
          txt: 'Are you sure you want to Delete Product?',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          // this.$store.dispatch('deleteShopPermission', id).then((response) => {
          //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          //     let shops = this.$store.getters.getShops
          //     shops.map((item, index) => {
          //       if(item.id === id) {
          //         shops[index].shop_oauth_param.key_and_token_exist = 0
          //       }
          //     })
          //   } else {
          //     setTimeout(() => {
          //       this.openNotify('success', 'Произошла ошибка')
          //     }, 200)
          //   }
          // })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    }

  }
</script>

<style lang="scss" scoped>

  td:first-child{
    text-align: center;
  }

  .site-table thead th,
  .site-table tbody td{
    padding-right: 40px;
  }

  .rank{
    display: flex;
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
    background-repeat: no-repeat;

    &.rank-1{
      background-image: url("../../../../../../../../assets/img/common/rank-1.svg");
    }

    &.rank-2{
      background-image: url("../../../../../../../../assets/img/common/rank-2.svg");
    }

    &.rank-3{
      background-image: url("../../../../../../../../assets/img/common/rank-3.svg");
    }
  }

</style>
