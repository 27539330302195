<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item">
        <DefaultInput
            :label="'№'"
            :type="'text'"
            v-model="number"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="'Listing ID'"
            :type="'text'"
            v-model="user"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="'Title'"
            :type="'text'"
            v-model="cellsFilter"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="'Price, $'"
            :type="'text'"
            v-model="upc"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="'Q-ty'"
            :type="'text'"
            v-model="sku"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
  // import DatePickerDefault from "../../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  // import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../../mixins/filterFuncMixin/filterFunc";

  export default {
    name: "EtsyRankFilter",
    components: {
      FilterBlock,
      MainButton,
      // DatePickerDefault,
      // DatePicker,
      DefaultInput,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc],

    data(){
      return {
        // date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        // name: this.filterGetParams.name ? this.filterGetParams.name : '',

        date: '',
        name: '',
        number: '',
        user: '',
        cellsFilter: '',
        upc: '',
        sku: '',
        nameEE: '',


      }
    },

    watch: {
      filterGetParams(newVal) {
        // this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.name = newVal.name ? newVal.name : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, ['date', 'name'])

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, ['name',])
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeShopTypesFilter(val) {
        this.shop = val.id
      },
    },
  }
</script>

<style scoped>

</style>
